.finance-final-header {
	background-color: var(--media-plan-container-grey);
}
.finance-final-cell {
	border-left: 2px solid rgba(234, 229, 231, 1);
	border-right: 2px solid rgba(234, 229, 231, 1);
	font-weight: 600;
}
.treed-row {
	background-color: var(--background-color);
}
.demand-row-no-advertiser {
    color: red !important
	/* background-color: rgb(255, 157, 157); */
}
.personal-row-no-data {
    color: red !important
	/* background-color: rgb(255, 157, 157); */
}
.warning {
    color: rgba(218, 174, 31, 1);
	/* background-color: rgb(255, 157, 157); */
}
.no-profit {
    color: red !important
}
.pinned-row {
	font-weight: 600;
	height: 2.5rem;
	background-color: var(--background-color);
	color: var(--text-color);
}
.edit-mode-on .is-not-editable{
	/* color: rgb(183, 183, 183); */
	font-size: 16px;
	/* margin-top: 4px; */
	padding-top: 3px !important;
	padding-left: 6px !important;
	color: var(--text-color);
	cursor:not-allowed
}
/* this is done for the doc viewer component */
#header-bar{
	display: none !important;
}
#image-renderer{
	background-color: #fafbfc !important;
}
.finance-action-button:hover{
color: rgba(163, 225, 87, 1) !important;
/* background: rgba(163, 225, 87, 1); */
}
