@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

* {
	font-family: 'Lato', Arial, sans-serif !important;
	box-sizing: border-box;
}

body {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	color: var(--text-color);
	margin: inherit;
	background: var(--background-color) !important;
	background: var(--background-color); /* fallback for old browsers */
}

/* width */
::-webkit-scrollbar {
	/* width: 5px; */
	display: hidden;
}
#news-container::-webkit-scrollbar {
	width: 5px;
}
#mediaPlanFiltersContainer::-webkit-scrollbar {
	width: 5px;
}
#mediaPlanFiltersContainer::-webkit-scrollbar-thumb {
	/* background: gray; */
	background: var(--media-plan-container-grey);
	border-radius: 10px;
	min-height: 7 10px;
	width: 100px;
}
#mediaPlanFiltersContainer::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px var(--media-plan-container-grey);
	border-radius: 10px;
}
/* .InovuaReactDataGrid__header-group {
	width: 69vw !important;
} */
/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px var(--media-plan-container-grey);
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--text-grey);
	border-radius: 10px;
	min-height: 7 10px;
	width: 100px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	/* -webkit-text-fill-color: [YourColorHere] !important; */
	transition: background-color 5000s ease-in-out 0s;
	-webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

html {
	width: 100%;
	height: 100%;
	/* background-color: rgb(255, 255, 255); */
}

.MuiDataGrid-panelFooter .MuiButtonBase-root {
	color: #000000 !important;
}

.sum-row-reports {
	background: rgba(64, 19, 129, 0.06) 100% !important;
	font-weight: 500;
	font-size: 14px;
}

.MuiDateRangePickerDay-rangeIntervalDayHighlight {
	background: rgba(181, 250, 97, 0.2) !important;
}

.rct-text {
	margin-top: 12px;
}
.action-button:hover {
	color: var(--svg-color) !important;
}
